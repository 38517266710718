<script>
export default {
    name: 'FooterComponent',
};
</script>

<template>
    <div class="container">
        <div class="footer-container">
            <div class="footer-left">
                <a class="title text-light footer-link" href="#head">My<span class="orange">VPN</span></a>
                <ul class="footer-left-list">
                    <li class="left-item text-medium text-light">ИП Гаврилов Игорь Владимирович</li>
                    <li class="left-item text-medium text-light">ОГРНИП: 317784700054435</li>
                    <li class="left-item text-medium text-light">© 2023 MyVPN</li>
                    
                    <li class="left-item text-medium"><a class="link" target="_blank"
                            href="https://drive.google.com/file/d/1Sc7JtKGe2ykGfwFrhkLLaCwQYt29cTuC/view?usp=drive_link">Политика
                            конфиденциальности</a>
                    </li>
                    <li class="left-item text-medium"><a class="link" target="_blank"
                            href="https://drive.google.com/file/d/1yhAnLJfwUygTMc90q3npwbudCeUCEW3p/view?usp=drive_link">Условия
                            пользования</a>
                    </li>
                    <li class="left-item text-medium"><a class="link" target="_blank"
                            href="https://docs.google.com/document/d/1v0gc2-LEdbfncGDyuZ7DClDjVXlwDie4XHD3j7gKx8E/edit?usp=sharing">Оферта</a>
                    </li>
                </ul>
            </div>
            <div class="footer-center">
                <ul class="center-list">
                    <li class="center-item"><img src="../../assets/images/master.svg" alt=""></li>
                    <li class="center-item"><img src="../../assets/images/visa.svg" alt=""></li>
                    <li class="center-item"><img src="../../assets/images/peace.svg" alt=""></li>
                </ul>
            </div>
            <div class="footer-right">
                <ul class="right-list">
                    <li class="right-item"><a target="_blank"
                            href="https://apps.apple.com/it/app/myvpn/id6479427630"><img
                                src="../../assets/images/apple.svg" alt="Apple"></a></li>
                    <li class="right-item"><a target="_blank"
                            href="https://play.google.com/store/apps/details?id=ai.myvpn.app"><img
                                src="../../assets/images/android.svg" alt="Google"></a></li>
                </ul>
            </div>

            <div class="footer-min">
                <ul class="min-top-list">
                    <li class="min-item"><img src="../../assets/images/master.svg" alt=""></li>
                    <li class="min-item"><img src="../../assets/images/visa.svg" alt=""></li>
                    <li class="min-item"><img src="../../assets/images/peace.svg" alt=""></li>
                </ul>
                <ul class="min-bottom-list">
                    <li class="min-item"><a target="_blank" href="https://apps.apple.com/it/app/myvpn/id6479427630"><img
                                src="../../assets/images/apple.svg" alt="Apple"></a></li>
                    <li class="min-item"><a target="_blank"
                            href="https://play.google.com/store/apps/details?id=ai.myvpn.app"><img
                                src="../../assets/images/android.svg" alt="Google"></a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style></style>