<template>
    <div class="container">
        <h2 class="title fs-56 text-center mb-60">Тариф My<span class="orange">VPN</span></h2>
        <div class="tariff-item box br-24" v-motion-pop-visible-once>
            <div class="tariff-top br-24 pd-40 bg-ligth-yellow">
                <h3 class="title fs-24">myvpn <span class="orange">ПОДПИСКА</span></h3>
                <p class="title fs-40">399 ₽/месяц</p>
                <button class="button orange-bg fs-20 width orange-hover"><a class="button-link"
                        href="#section1">Подключить</a></button>
            </div>
            <div class="tariff-bottom pd-40">
                <div class="bottom-item">
                    <img src="../assets/images/check-circle.svg" alt="check">
                    <span class="text-medium fs-20">Без регистрации</span>
                </div>
                <div class="bottom-item">
                    <img src="../assets/images/check-circle.svg" alt="check">
                    <span class="text-medium fs-20">Без рекламы</span>
                </div>
                <div class="bottom-item">
                    <img src="../assets/images/check-circle.svg" alt="check">
                    <span class="text-medium fs-20">Без сбора данных</span>
                </div>
                <div class="bottom-item">
                    <img src="../assets/images/check-circle.svg" alt="check">
                    <span class="text-medium fs-20">Высокая скорость</span>
                </div>
                <div class="bottom-item">
                    <img src="../assets/images/check-circle.svg" alt="check">
                    <span class="text-medium fs-20">Защищенный доступ к сети</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tariff',
    // data() {
    //     return {
    //         tariffs: [
    //             {
    //                 id: 1,
    //                 name: 'myvpn',
    //                 style: 'MyVPN lite',
    //                 description: 'общий сервер',
    //                 price: '299 ₽/месяц',
    //                 topBgClass: 'tariff-top br-24 pd-40 bg-ligth-yellow',
    //                 buttonClass: 'button orange-bg fs-20 width orange-hover',
    //                 buttonText: 'Подключить',
    //                 features: [
    //                     'Защищенный доступ к сайтам и приложениям',
    //                     'Устойчивость к взлому и блокировкам',
    //                     'Возможность смены локации',
    //                     'Оплата картой РФ или USDT',
    //                     'Бесплатный период 3 дня',
    //                     'Оплата только за дни использования'
    //                 ],
    //             },
    //             {
    //                 id: 2,
    //                 name: 'myvpn',
    //                 style: 'MyVPN pro',
    //                 description: 'личный сервер',
    //                 price: '3499 ₽/год',
    //                 topBgClass: 'tariff-top br-24 pd-40 bg-light',
    //                 buttonClass: 'button bg-violet text-light fs-20 width violet-hover',
    //                 buttonText: 'Подключить',
    //                 features: [
    //                     'Защищенный доступ к сайтам и приложениям',
    //                     'Устойчивость к взлому',
    //                     'Возможность смены локации',
    //                     'Оплата картой РФ',
    //                     'Бесплатный период 3 дня',
    //                     'Оплата только за дни использования',
    //                     'Выделенный сервер',
    //                     'Максимальная скорость',
    //                 ],
    //             },
    //         ],
    //     };
    // },

};
</script>

<style></style>