<script>
export default {
        name: 'Location',
        mounted () {

        },
        computed: {
                utm_id() {
                  return this.$store.getters.utm
                },
                bot_url() {
                  return this.$store.getters.bot_url
                }
        },
}
</script>

<template>
        <div class="container">
                <div class="location-container text-center">
                        <img class="loc-img" src="../assets/images/location.png" alt="">
                        <h2 class="title mb-60 fs-56">Локации My<span class="orange">VPN</span></h2>
                        <ul class="location-list mb-60">
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc1"><img
                                                class="location-img" src="../assets/images/US.svg" alt="USA"><span
                                                class="text-semi text-grey">США</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc2"><img
                                                class="location-img" src="../assets/images/JP.svg" alt="JAPAN"><span
                                                class="text-semi text-grey">Япония</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc3"><img
                                                class="location-img" src="../assets/images/RU.svg" alt="RUSSIA"><span
                                                class="text-semi text-grey">Россия</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc4"><img
                                                class="location-img" src="../assets/images/KW.svg" alt="KW"><span
                                                class="text-semi text-grey">ОАЭ</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc5"><img
                                                class="location-img" src="../assets/images/FR.svg" alt="FRENCH"><span
                                                class="text-semi text-grey">Франция</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc6"><img
                                                class="location-img" src="../assets/images/GB.svg" alt="GB"><span
                                                class="text-semi text-grey">Великобритания</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc7"><img
                                                class="location-img" src="../assets/images/ES.svg" alt="ES"><span
                                                class="text-semi text-grey">Испания</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc8"><img
                                                class="location-img" src="../assets/images/DE.svg" alt="DE"><span
                                                class="text-semi text-grey">Германия</span></li>
                                <li v-motion-fade-visible-once :delay="300" class="location-item br-24 pd-24 box loc9"><img
                                                class="location-img" src="../assets/images/IT.svg" alt="IT"><span
                                                class="text-semi text-grey">Италия</span></li>
                        </ul>
                        <button class="button orange-bg fs-28 orange-hover">
                                <a class="button-link" 
                                href="#section1">Попробовать бесплатно</a>
                        </button>
                </div>
        </div>
</template>
