<script>
export default {
    name: 'MainComponent',
    data() {
        return {
            platform:""
        }

    },
    computed: {
                utm_id() {
                  return this.$store.getters.utm
                },
                bot_url() {
                  return this.$store.getters.bot_url
                }
        },
        mounted() {
        }
}
</script>

<template>
    <div class="container">
        <div class="main-container" v-motion-fade>
            <h1 class="title title-big main-title">Надежный <span class="orange">VPN</span> всего за 399 ₽ в месяц</h1>
            <ul class="main-list">
                <li class="main-item text-medium fs-20"><img src="../assets/images/easy-reg-orange.svg" alt="logo">Без регистрации</li>
                <li class="main-item text-medium fs-20"><img src="../assets/images/megaphone-orange.svg" alt="logo">Без рекламы</li>
                <li class="main-item text-medium fs-20"><img src="../assets/images/eye-off-orange.svg" alt="logo">Без сбора данных</li>
            </ul>
            <button class="button orange-bg fs-28 orange-hover">
                <a href="#section1" class="button-link">
                    Попробовать бесплатно
                </a>
            </button>
            <img class="main-img" src="../assets/images/main.png" alt="">
        </div>

    </div>
</template>

<style></style>